import { ReactNode, Suspense, useEffect } from "react";
import { LoadingProvider } from "./loading";
import NotifyProvider from "./notify";
import { CommonDataProvider } from "./common-data";
import { ChatList, MobileChatDetail } from "Components";
import useMediaBreakpoints from "hooks/useMediaBreakpoints";
import { LayoutProvider } from "./layout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GG_APP_ID } from "constants/schemas";
import { SEARCH_PARAMS } from "constants/routes";
import { getCookie, setCookie } from "helpers/cookie";
import NotificationHandler from "helpers/notification";
import ScrollToTop from "Components/ScrollToTop";

const AppProvider = ({ children }: { children: ReactNode }) => {
  const { downMobileBreakpoint: isMobile } = useMediaBreakpoints();

  const getUtmSource = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmSource = searchParams.get(SEARCH_PARAMS.utmSource);
    const localUtmSource = getCookie(SEARCH_PARAMS.utmSource);
    if (!localUtmSource) {
      setCookie(SEARCH_PARAMS.utmSource, utmSource || "origin", 30);
    }
  };

  useEffect(() => {
    getUtmSource();
  }, []);

  return (
    <GoogleOAuthProvider clientId={GG_APP_ID}>
      <CommonDataProvider>
        <LayoutProvider>
          <LoadingProvider>
            <NotifyProvider>
              {children}

              <Suspense fallback={<div />}>
                {isMobile ? <MobileChatDetail /> : <ChatList />}
              </Suspense>
              <ScrollToTop />

              <NotificationHandler />
            </NotifyProvider>
          </LoadingProvider>
        </LayoutProvider>
      </CommonDataProvider>
    </GoogleOAuthProvider>
  );
};

export default AppProvider;
