import { Tabs } from "@mui/material";
import { StyledTab } from "./styles";

const StyledTabs = ({ tabs = [], selectedValue, onChange }) => {
  return (
    <Tabs
      value={selectedValue}
      onChange={onChange}
      variant="scrollable"
      scrollButtons="auto"
      TabIndicatorProps={{ style: { background: "#008934" } }}
      sx={{ minHeight: 40 }}
    >
      {tabs.map((tab, i) => (
        <StyledTab
          key={i}
          label={tab.label}
          value={tab.value}
          isSelected={selectedValue === tab.value}
          isLastest={i >= tabs?.length - 1}
        />
      ))}
    </Tabs>
  );
};

export default StyledTabs;
