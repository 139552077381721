import { forwardRef } from "react";
import StyledTextField from "./styles";
import { MenuItem, TextFieldProps } from "@mui/material";

const RoundInputWithLabel = forwardRef(
  (props: TextFieldProps & { items?: any }, ref: any) => {
    return (
      <StyledTextField
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: { maxHeight: 350, minWidth: "initial" },
            },
          },
        }}
        {...props}
        ref={ref}
      >
        {props.items &&
          props.items?.map((item: any) => (
            <MenuItem key={item?.value} value={item?.value}>
              {item?.label}
            </MenuItem>
          ))}
        {props.children}
      </StyledTextField>
    );
  }
);

export default RoundInputWithLabel;
