import { FormattedMessage } from "react-intl";

import { translationMessages } from "../../i18n";
import { useLanguageContext } from "../LanguageProvider";

export default function FormattedLanguage({
  id,
  values = {},
  type = "message",
}) {
  const { currentLanguage } = useLanguageContext;

  const getComponent = () => {
    let matchMessage = translationMessages[currentLanguage][id];
    switch (type) {
      case "message":
        return (
          <FormattedMessage
            id={id}
            defaultMessage={matchMessage}
            values={values}
          />
        );

      case "string":
        return matchMessage;
      default:
        return;
    }
  };

  return getComponent();
}
