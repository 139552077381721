import { Dialog, DialogContent } from "@mui/material";
import useStyles from "./styles";

export default function CustomDialog({content, showDia, onClose, diaAtt, contAtt}) {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={showDia}
        onClose={onClose}
        className={classes.dialog} 
        sx={{...diaAtt}}
      >
        <DialogContent className={classes.dialogContent} 
        sx={{
          padding: {xs: 2, md: 2}, ...contAtt}}
        >
          {content}
        </DialogContent>
      </Dialog>
    </>
  );
}
