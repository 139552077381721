import enTranslation from "./lang/en.json";
import viTranslation from "./lang/vi.json";
import zhTranslation from "./lang/zh.json";

const APP_LOCALE = {
  EN: "en",
  VI: "vi",
  ZH: "zh",
};

const translationMessages = {
  en: enTranslation,
  vi: viTranslation,
  zh: zhTranslation,
};

export { APP_LOCALE, translationMessages };
